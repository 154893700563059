import { media } from 'src/ui/utils/media';
import styled, { css } from 'styled-components';
import { Button } from '~/ui/kit/atoms/Button';
import { gaps } from '~/ui/kit/constants/sizes';
import { PortfolioSelector as BasePortfolioSelector } from '~/features/portfolioSelector';

export const Header = styled.header`
  --eko-header-bg-color: var(--eko-header-bg-color-integrated);
  --eko-header-bg-opened-color: var(--eko-background-color);
  --eko-header-fg-color: var(--eko-primary-color);
  --eko-header-burger-opened-color: var(--eko-third-color);
  --eko-header-logout-color: var(--eko-text-color);

  background: var(--eko-header-bg-color);
  position: fixed;
  top: var(--q2-real-top, 0px);
  left: 0;
  right: 0;
  touch-action: none;
  z-index: 999999;
  padding: ${gaps.m}px;

  ${media.lg`
    padding: ${gaps.xl}px;
  `}
`;

export const PortfolioSelector = styled(BasePortfolioSelector)`
  max-width: calc(100% - 80px);
  padding-left: 16px;
  margin-left: -16px;

  ${media.lg`
    max-width: 250px;
  `};
`;

export const ControlsContainer = styled.div`
  display: flex;
  gap: ${gaps.m}px;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
`;

type MenuItemProps = {
  $isActive: boolean;
};

export const MenuItem = styled(Button).attrs<MenuItemProps>({
  type: 'link',
  noDecoration: true,
})<MenuItemProps>`
  &.ant-btn {
    display: none;

    @media (min-width: 769px) {
      display: flex;
    }

    column-gap: 8px;
    padding: 8px 32px;
    text-decoration: none;
    border-radius: 4px;
    align-items: center;

    @media (max-width: 1200px) {
      padding-inline: 12px;
    }

    ${({ $isActive }) =>
      $isActive &&
      css`
        background-color: var(--eko-header-menu-item-active-bg);

        .menuItem__title {
          font-weight: 700;
        }
      `}
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;

  svg * {
    fill: var(--eko-header-fg-color);
  }
`;
