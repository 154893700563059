import React, { useCallback, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { constVoid } from 'fp-ts/function';

import { closeAppMessage } from '~/shared/closeApp';

import * as S from './IdleTime.widget.styled';

type Props = {
  /* @description in seconds */
  idleTime: number;
  /* @description in seconds */
  promptBeforeIdleTime: number;
  title?: React.ReactNode;
  text?: React.ReactNode;
};

const DEFAULT_TITLE = 'Are you still here?';
const DEFAULT_DESCRIPTION = `
  We detected that you have no activity for a long time. 
  Please confirm that you are still here or we will interrupt you session
`;

export const IdleTime: React.FC<Props> = ({ title = DEFAULT_TITLE, text = DEFAULT_DESCRIPTION, ...props }) => {
  const [showIdlePopup, setShowIdlePopup] = useState(false);

  const onIdle = useCallback(() => {
    closeAppMessage();
  }, []);

  const onPrompt = useCallback(() => {
    setShowIdlePopup(true);
  }, []);

  const { reset } = useIdleTimer({
    timeout: props.idleTime * 1000,
    promptBeforeIdle: props.promptBeforeIdleTime * 1000,
    onPrompt,
    onIdle,
    throttle: 500,
  });

  const onClickYes = useCallback(() => {
    reset();
    setShowIdlePopup(false);
  }, [reset]);

  return (
    <S.Container
      disableClose
      title={title}
      subText={text}
      isVisible={showIdlePopup}
      isLoading={false}
      yesBtnText="Confirm"
      onClickYes={onClickYes}
      onClickNo={constVoid}
    />
  );
};
