import React from 'react';
import cn from 'classnames';

import * as S from './BurgetButton.styled';

interface Props {
  className: string;
  opened: boolean;
  onClick: () => void;
}

export const BurgerButton: React.FC<Props> = (props) => {
  return (
    <S.MenuButton className={cn(props.className, 'burger')} onClick={props.onClick} $opened={props.opened}>
      <S.Wrapper>
        <S.Item />
        <S.Item />
        <S.Item />
      </S.Wrapper>
      Menu
    </S.MenuButton>
  );
};
