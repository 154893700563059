import styled from 'styled-components';
import { Button } from '~/ui/kit/atoms/Button';
import { fontSize } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Item = styled.div`
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: currentColor;
  transition:
    transform 0.25s ease,
    opacity 0.25s ease;
`;

export const MenuButton = styled(Button).attrs<{ $opened?: boolean }>({ type: 'ghost', $fs: 'xs' })<{
  $opened?: boolean;
}>`
  ${fontSize};
  font-weight: bold;
  cursor: pointer;
  animation: none;
  padding: 0 ${gaps.xxs}px;
  height: 24px;
  z-index: 100;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  ${Item} {
    &:nth-child(1) {
      transform: ${({ $opened }) => $opened && `rotate(-45deg) translate(-5px, 5px) scale(1.3)`};
    }

    &:nth-child(2) {
      opacity: ${({ $opened }) => $opened && `0`};
    }

    &:nth-child(3) {
      transform: ${({ $opened }) => $opened && `rotate(45deg) translate(-5px, -5px) scale(1.3)`};
    }
  }
`;
